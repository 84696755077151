<template>

  <div style="flex: 1; padding: 15px; background-color: white;">
    <TabView v-model:activeIndex="activePageTab">
      <TabPanel :header="_t('label_user')" style="padding: 15px">
        <div class="p-grid">
          <Card v-if="getUser" class="style p-col">
            <template #title>
              <div class="p-d-flex p-jc-between">
                <div>
                  <h3 class="p-m-0">
                    <strong>{{ _t('label_user_account') }}</strong>
                  </h3>
                </div>
              </div>
            </template>
            <template #content>
              <div class="p-grid">
                <div class="p-col-1 info-block__labels p-text-right">
                  <div class="p-d-block p-text-bold p-field">{{ _t('label_name') }}</div>
                  <div class="p-d-block p-text-bold p-field">{{ _t('label_surname') }}</div>
                  <div class="p-d-block p-text-bold p-field">{{ _t('label_login') }}</div>
                  <div class="p-d-block p-text-bold p-field">{{ _t('label_organization') }}</div>
                  <div class="p-d-block p-text-bold p-field">{{ _t('label_language') }}:</div>
                  <div class="p-d-block p-text-bold p-field">{{ _t('Type') }}</div>
                  <div class="p-d-block p-text-bold p-field">{{ _t('label_personal_id') }}</div>
                </div>
                <div class="p-col-7 info-block__values">
                  <div class="p-d-block p-field">
                    <InputText v-model="getUser.name" class="disabled" disabled style="min-width: 15rem;"/>
                  </div>
                  <div class="p-d-block p-field">
                    <InputText v-model="getUser.surname" class="disabled" disabled style="min-width: 15rem;"/>
                  </div>
                  <div class="p-d-block p-field">
                    <InputText v-model="getUser.login" class="disabled" disabled style="min-width: 15rem;"/>
                  </div>
                  <div class="p-d-block p-field">
                    <OrganizationLink v-model="getUser" :editMode="false" modelType="PARTICIPANT"/>
                  </div>
                  <div class="p-d-block p-field">
                    <Dropdown v-model="getUser.language"
                              :options="availableLanguages"
                              :placeholder="_t('label_language')"
                              optionLabel="label"
                              optionValue="value"
                              style="min-width: 15rem;"
                              @change="saveAccount(true)"
                    />
                  </div>
                  <div class="p-d-block p-field">
                    <InputText v-model="getUser.type" class="disabled" disabled style="min-width: 15rem;"/>
                  </div>
                  <div class="p-d-block p-field">
                    <InputText v-model="getUser.personalId" class="disabled" disabled style="min-width: 15rem;"/>
                  </div>
                </div>
                <div class="p-col-12 advanced-block p-grid">
                  <div class="p-col-2 p-pt-5">
                    <Button
                        v-tooltip.top="isSetResetToggle ? _t('label_Display_QR_code') : _t('label_reset_2fa_participant')"
                        :icon="isSetResetToggle ? 'pi pi-arrow-circle-down' : 'pi pi-refresh'"
                        :label="isSetResetToggle ? _t('label_Display_QR_code') : _t('label_reset_2fa_participant')"
                        class="p-button-outlined p-button-success"
                        style="width: 100%"
                        @click="handleClickSetReset"/>
                    <Button :class="{'p-button-outlined': activeTab!==1}"
                            :label="_t('menu.user_pass')"
                            class="p-button-success p-mt-1"
                            style="width: 100%" @click="activeTab=1"/>
                    <Button :label="_t('label_logout')"
                            class="p-button-outlined p-button-danger p-mt-1"
                            style="width: 100%"
                            @click="logout"/>
                  </div>
                  <div class="p-col-10">
                    <Fieldset v-if="activeTab === 1" :legend="_t('label_change_pass')">
                      <div class="p-d-flex p-flex-row">
                        <div class="p-d-inline-flex p-flex-column">
                          <div class="p-d-inline-block p-text-right p-text-bold p-mt-1 p-mr-4">
                            <label class="p-d-block"
                                   for="old_pass"
                                   style="min-height: 30px">
                              {{ _t('label_old_password') }}
                            </label>
                          </div>
                          <div class="p-d-inline-block p-text-right p-text-bold p-mt-1 p-mr-4">
                            <label class="p-d-block"
                                   for="new_pass"
                                   style="min-height: 30px">
                              {{ _t('label_new_password') }}
                            </label>
                          </div>
                          <div class="p-d-inline-block p-text-right p-text-bold p-mt-1 p-mr-4">
                            <label class="p-d-block"
                                   for="repeat_pass"
                                   style="min-height: 30px">
                              {{ _t('label_password_repeat') }}
                            </label>
                          </div>
                        </div>
                        <div class="p-d-inline-flex p-flex-column">
                          <div class="p-d-inline-block">
                            <InputText id="old_pass" v-model="password.current" autocomplete="off"
                                       style="min-width: 15rem"
                                       type="password"/>
                          </div>
                          <div class="p-d-inline-block">
                            <Password id="new_pass" v-model="password.new" inputStyle="min-width: 15rem" toggleMask>
                              <template #header>
                                <h6>{{ _t('label_pick_password') }}</h6>
                              </template>
                              <template #footer>
                                <Divider/>
                                <p class="p-mt-2">{{ _t('label_password_suggestions') }}</p>
                                <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
                                  <li>{{ _t('label_password_at_least_one_lowercase') }}</li>
                                  <li>{{ _t('label_password_at_least_one_uppercase') }}</li>
                                  <li>{{ _t('label_password_at_least_one_numeric') }}</li>
                                  <li>{{ _t('label_password_minimum_chars') }}</li>
                                </ul>
                              </template>
                            </Password>
                          </div>
                          <div class="p-d-inline-block">
                            <Password id="repeat_pass" v-model="password.repeat" inputStyle="min-width: 15rem"
                                      toggleMask/>
                          </div>
                          <div class="p-d-inline-block p-text-right p-mt-2">
                            <Button :label="_t('Save')" class="p-button-success" @click="savePassword"/>
                          </div>
                        </div>
                      </div>
                    </Fieldset>
                    <Fieldset v-if="activeTab === 2" legend="Something else">
                      2222
                    </Fieldset>
                    <Fieldset v-if="activeTab === 3" legend="Something else">
                      3333
                    </Fieldset>
                  </div>
                </div>
              </div>
            </template>
          </Card>
          <Dialog v-model:visible="isSet2faModal"
                  :header="!temp2faAuth.qrCodePath ? _t('label_password_confirm') : _t('label_scan_qr_code')" modal
                  style="width: 30vw;">
            <div v-if="!temp2faAuth.qrCodePath"
                 style="width: 50%; margin: 0 auto; display: flex; flex-direction: column; align-items: center; justify-content: center;">
              <InputText v-model="temp2faAuth.setPasswordData" :placeholder="_t('label_password')"
                         style="width: 100%; margin-bottom: 20px;"
                         type="password"/>
              <Button :disabled="!temp2faAuth.setPasswordData" :label="_t('label_Display_QR_code')" style="width: 100%;"
                      @click="set2faParticipant"/>
            </div>
            <div v-else>
              <div style="width: 100%; display: flex; justify-content: center;">
                <img :src="temp2faAuth.qrCodePath" alt="qr_code"/>
              </div>
              <br>
              <InputText v-model="temp2faAuth.code" :placeholder="_t('label_enter_otp')"
                         style="width: 100%; margin-bottom: 20px;"/>
              <br>
              <Button :label="_t('label_Display_QR_code')" style="width: 100%;" @click="auth2faParticipant"/>
            </div>
          </Dialog>
        </div>
      </TabPanel>

      <TabPanel :header="_t('menu_system_tables')" style="display: flex; padding: 15px; height: 100vh">
        <div class="p-d-flex p-jc-between">
          <div>
            <h3 class="p-m-0"><strong>{{ _t('label_system_tables_columns_account') }}</strong></h3>
          </div>
          <div>
            <Button v-if="isEditable" v-tooltip.bottom="_t('Cancel')"
                    class="p-button-rounded p-button-danger p-button-text"
                    icon="pi pi-times"
                    @click="isEditable = false"/>
            <Button v-if="isEditable" v-tooltip.bottom="_t('Save')"
                    class="p-button-rounded p-button-text p-button-success"
                    icon="pi pi-check"
                    @click="handleSaveViews"/>
            <Button v-if="!isEditable" v-tooltip.bottom="_t('Edit')"
                    class="p-button-rounded p-button-text p-button-secondary"
                    icon="pi pi-pencil"
                    @click="isEditable = true"/>
          </div>
        </div>
        <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
          <Card v-for="(element,key) in userColsData"
                :key="key"
                class="style p-col"
                style="padding: 5px 20px; min-width: 410px; max-width: 410px;">
            <template #title>
              <h3>
                <strong>{{ _t(getTitle(key)) }}</strong>
              </h3>
            </template>
            <template #content>
              <div style="display: flex; flex-wrap: wrap; justify-content: space-between; align-items: center;">
                <div v-for="(item, elemKey) in element" :key="item.value + item.label"
                     :hidden="!orgColsData[key][elemKey]['checked']?.length > 0"
                     class="field-checkbox" style="width: 160px; margin: 5px 0;">
                  <Checkbox v-model="item.checked"
                            :disabled="!isEditable" :inputId="item.value"
                            :name="item.value" :value="item.value"/>
                  <label v-if="orgColsData[key][elemKey]['checked']?.length > 0" :for="item.value"
                         style="margin-left: 15px;">{{ _t(`${item.label}`) }}</label>
                </div>
              </div>
            </template>
          </Card>
        </div>
      </TabPanel>
    </TabView>
  </div>
  <Toast/>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {generateQrUrl, get2faStatus, login, logout, reset2faStatus} from "@/api/auth";
import {getOrganizationColumns, getUserColumns, setOrganizationColumns, setUserColumns} from "@/api/uiColumns";
import {initialColsData} from "@/constants/constants";

export default {
  data: () => ({
    activeTab: null,
    activePageTab: 0,
    password: {
      current: '',
      repeat: '',
      new: '',
    },
    isEditable: false,
    availableLanguages: [],
    orgColsData: [],
    userColsData: [],
    isSet2faModal: false,
    isSetResetToggle: false,
    temp2faAuth: {
      setPasswordData: '',
      userLogin: '',
      qrCodePath: '',
      code: ''
    },
  }),
  mounted() {
    try {
      const userInfo = JSON.parse(localStorage.getItem('user'));
      if (userInfo && userInfo.id) {
        this.callUser(userInfo.id).then((user) => {
          if (user && user.login) {
            this.temp2faAuth.userLogin = user.login
            get2faStatus(user.login).then((res) => {
              if (res.data && res.data.status === 'NONE') {
                // this.isSetResetToggle = true
              }
            })
          }
        })
      } else {
        this.logout();
      }
    } catch (e) {
      console.log(e);
    }
    this.availableLanguages = [
      {value: 'ENGLISH', label: this._t('ENGLISH')},
      {value: 'GERMAN', label: this._t('GERMAN')},
      // {value: 'FRENCH', label: this._t('FRENCH')},
      {value: 'ITALIAN', label: this._t('ITALIAN')},
      {value: 'CZECH', label: this._t('CZECH')},
      // {value: 'GREEK', label: this._t('GREEK')},
      // {value: 'SPANISH', label: this._t('SPANISH')}
    ];

    getOrganizationColumns().then((orgRes) => {
      if (orgRes.data) {
        this.orgColsData = orgRes.data
        getUserColumns().then((userRes) => {
          if (!userRes.data || Object.values(userRes.data)[0] === [] || typeof userRes.data !== 'object' || Array.isArray(userRes.data)) {
            this.userColsData = initialColsData
          } else {
            this.userColsData = userRes.data
          }

          if (Object.keys(orgRes.data).length !== Object.keys(userRes.data).length) {

            if (Object.keys(orgRes.data).length > Object.keys(userRes.data).length) {
              let missingElement = [];
              Object.keys(orgRes.data).forEach((el) => {
                if (!Object.keys(userRes.data).includes(el)) {
                  missingElement.push(el)
                }
              })

              if (missingElement.length) {
                let newObj = {...orgRes.data}
                missingElement.map((el) => orgRes.data[el].find((findEl) => findEl.checked.length).length ? newObj[el] = orgRes.data[el] : el)
                this.userColsData = newObj;
                setUserColumns(newObj);
              }
            } else {
              let missingElement = [];
              Object.keys(userRes.data).forEach((el) => {
                if (!Object.keys(orgRes.data).includes(el)) {
                  missingElement.push(el)
                }
              })

              if (missingElement.length) {
                let newColumns = {...userRes.data}
                missingElement.map((el) => delete newColumns[el])

                this.userColsData = newColumns;
                setUserColumns(newColumns);
                window.location.reload();
              }
            }
          }
        }).catch((err) => console.log('error', err))
      } else {
        setOrganizationColumns(initialColsData).then(() => getOrganizationColumns().then((orgRes) => {
          if (orgRes.data) {
            this.orgColsData = orgRes.data
            getUserColumns().then((userRes) => {
              if (!userRes.data || Object.values(userRes.data)[0] === [] || typeof userRes.data !== 'object') {
                this.userColsData = initialColsData
              } else {
                this.userColsData = userRes.data
              }
            }).catch((err) => console.log('error', err))
          }
        }).catch((err) => console.log('error', err)))

      }
    }).catch((err) => console.log('error', err))
  },
  methods: {
    ...mapActions({
      'callUser': 'users/callUser',
      'callUpdateUser': 'users/callUpdateUser',
      'callChangePassword': 'users/callChangePassword',
    }),
    handleClickSetReset() {
      this.temp2faAuth.qrCodePath = ''
      this.temp2faAuth.setPasswordData = ''
      if (this.isSetResetToggle) {
        this.isSet2faModal = true
      } else {
        this.reset2faParticipant()
      }
    },
    set2faParticipant() {

      generateQrUrl({username: this.temp2faAuth.userLogin, password: this.temp2faAuth.setPasswordData})
          .then((response) => {
            this.temp2faAuth.qrCodePath = response.data
          })
          .catch(error => {
            this.$toast.add({
              severity: 'error', summary: 'Successful',
              detail: this._t(error.response.data.reason), life: 3000
            })
          })
    },
    auth2faParticipant() {
      login({
        username: this.temp2faAuth.userLogin,
        password: this.temp2faAuth.setPasswordData,
        verificationCode: this.temp2faAuth.code
      }).then(() => {
            window.location.reload()
          }
      ).catch(() => {
        window.location.reload()
      })

    },
    reset2faParticipant() {
      reset2faStatus(this.getUser.login).then((res) => {
        if (res.status === 200) {
          this.$toast.add({
            severity: 'success',
            summary: 'Successful',
            detail: this._t('label_reset_2fa_participant_success'),
            life: 3000
          })
          // this.isSetResetToggle = true
        }
      }).catch(error => {
        this.$toast.add({
          severity: 'error',
          summary: 'Successful',
          detail: this._t(error.response.data.reason),
          life: 3000
        })
      })
    },
    getTitle(title) {
      if (title === 'alarmParticipantGroupsItems')
        return 'PARTICIPANT_GROUP'
      if (title === 'teamMediaSchedulesItems')
        return 'PARTICIPANT_ROTATIONAL_CAL'
      if (title === 'standbySchedulesItems')
        return 'label_ap_rotationals'
      if (title === 'alarmParticipantPlansItems')
        return 'label_alarm_plans'
      if (title === 'alarmParticipantsItems')
        return 'label_participants'
      if (title === 'alarmPointItems')
        return 'menu.alarm_definition.new'
      if (title === 'alarmTableItems')
        return 'label_alarmtable'
    },
    handleSaveViews() {
      setUserColumns(this.userColsData)
      this.isEditable = false
    },
    logout() {
      logout().then(() => {
        localStorage.removeItem('authtoken')
        localStorage.removeItem('orgId')
        localStorage.removeItem('orgData')
        localStorage.removeItem('user')
        localStorage.removeItem('multiOrgData')
        localStorage.removeItem('multiOrgIds')
        window.location.href = '/'
      })
    },
    savePassword() {
      if (this.password.current && this.password.new && this.password.repeat) {
        if (this.password.new === this.password.repeat) {
          return this.callChangePassword({oldPassword: this.password.current, newPassword: this.password.new})
              .then(() => {
                    this.password.current = '';
                    this.password.new = '';
                    this.password.repeat = '';
                    this.$toast.add({
                      severity: 'success',
                      summary: this._t('label_change_pass'),
                      detail: 'Password was changed successfully',
                      life: 3000
                    });
                  },
                  error => {
                    this.$toast.add({
                      severity: 'error',
                      summary: this._t('label_change_pass'),
                      detail: error,
                      life: 3000
                    });
                  })
        } else {
          this.$toast.add({
            severity: 'error',
            summary: this._t('label_change_pass'),
            detail: 'Passwords do not match',
            life: 3000
          });
        }
      } else {
        this.$toast.add({
          severity: 'error',
          summary: this._t('label_change_pass'),
          detail: 'All fields are required',
          life: 3000
        });
      }
    },
    saveAccount(refresh = false) {
      return this.callUpdateUser()
          .then((response) => {
                this.$toast.add({
                  severity: 'success',
                  summary: 'Update account',
                  detail: 'Account was updated successfully',
                  life: 3000
                });
                localStorage.setItem('lang', response.language);
                if (refresh) {
                  document.location.reload();
                }
              },
              error => {
                this.$toast.add({severity: 'error', summary: 'Update account', detail: error, life: 3000});
              });
    }
  },
  computed: {
    ...mapGetters({
      'getUser': 'users/getUser',
    }),
  },
}
</script>

<style lang="scss" scoped>
.info-block__labels .p-field {
  min-height: 30px;
  padding: 0.5rem 0 0;
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;

}

.p-component {
  font-size: 13px;
}

.p-tabview-nav {
  padding: 15px 15px 0 20px;
  background-color: white;
}
</style>
